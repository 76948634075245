export default [
  {
    key: 'id',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'accountName',
    sortable: false,
    sortField: '',
    label: 'field.account_name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'accountUid',
    sortable: false,
    sortField: '',
    label: 'field.game_id',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'accountZoneid',
    sortable: false,
    sortField: '',
    label: 'field.serverId',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'diamondAmount',
    sortable: false,
    sortField: 'amount',
    label: 'field.amount_diamond',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'price',
    sortable: false,
    sortField: 'price',
    label: 'field.price',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'orderType',
    sortable: false,
    label: 'field.order_type',
    variant: 'light',
  },

  {
    key: 'createdAt',
    sortable: false,
    sortField: '',
    label: 'field.createDate',
  },

  {
    key: 'actions',
    label: 'general.action',
  },
]
