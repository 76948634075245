<template>
  <div>
    <load-profile></load-profile>

    <n-search-container
      :show-header="true"
      @search="search"
      @reset="reset"
      :fields="searchFields"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body v-if="!loadingReport && $can('read', 'report')">
      <b-card-header
        class="cursor-pointer"
        role="tab"
        data-toggle="collapse"
        @click="updateVisible(!visible)"
      >
        <b-row class="w-100" align-v="center">
          <b-col align-self="center">
            <b>
              <feather-icon icon="BookIcon" size="18" />
              {{ $t('breadcrumb.report') }}
            </b>
          </b-col>
          <b-col>
            <feather-icon
              v-if="!visible"
              icon="ChevronDownIcon"
              size="16"
              v-b-tooltip.hover
              class="float-right"
            />
            <feather-icon
              v-if="visible"
              icon="ChevronUpIcon"
              size="16"
              v-b-tooltip.hover
              class="float-right"
            />
          </b-col>
        </b-row>
      </b-card-header>
      <b-collapse
        :id="collapseItemID"
        v-model="visible"
        :accordion="accordion"
        role="tabpanel"
      >
        <b-card-body>
          <b-row>
            <b-col md="4">
              <b-card>
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="warning">
                      <feather-icon size="24" icon="DollarSignIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ report.preOrderSum }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ $t('field.' + 'preOrder') }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-card>
            </b-col>
          </b-row>
        </b-card-body>
      </b-collapse>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t('general.entries') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(orderType)="data">
            <b-badge
              pill
              variant="warning"
              badge-glow
              v-if="data.item.orderType == 'pre_order'"
            >
              {{ $t(`customer.preorder`) }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                :to="{
                  name: 'view-pre-order',
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('general.view')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BBadge,
  BCollapse,
  BMedia,
  BMediaAside,
  BCardText,
  BCardBody,
  BCardHeader,
  BMediaBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NButtonDelete from '@/components/NButtonDelete'
import NTable from '@/components/NTable'
import tableFields from './tableField'
import searchInputs from './searchInput'
import { v4 as uuidv4 } from 'uuid'

const TransactionRepository = Repository.get('transaction')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BBadge,
    BCollapse,
    BMedia,
    BMediaAside,
    BCardText,
    BCardBody,
    BCardHeader,
    BMediaBody,
  },
  watch: {
    perPage(value) {
      this.list(1)
    },
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
    activeSearch() {
      return !!this.$route.query.name || !!this.$route.query.isEnable
    },
    fields() {
      let fieldKeys = tableFields.map((item) => {
        if (item.key == 'name') {
          item.sortField = `name_${this.$i18n.locale}`
        }
        return item
      })
      return fieldKeys
    },
  },

  data() {
    this.searchFields = this.searchFields.map((field) => {
      if (field.key == 'sortPayment') {
        field.options = [
          { id: 'desc', name: this.$t('field.mostPay') },
          { id: 'asc', name: this.$t('field.leastPay') },
        ]
      }
      if (field.key == 'sortType') {
        field.options = [
          { id: 'manual', name: this.$t('field.manual') },
          { id: 'instant', name: this.$t('field.instant') },
          { id: 'pre_order', name: this.$t('field.preOrder') },
        ]
      }
      return field
    })
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        name: this.$route.query.name || null,
        isEnable: this.$route.query.isEnable || null,
        limit: this.$store.state.pagination.perPage || 10,
        sortPay: null,
        sortType: null,
        type: null,
      },
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      loadingReport: false,
      report: {
        preOrderSum: 0,
        manualSum: 0,
        instantSum: 0,
      },
    }
  },
  mounted() {
    this.getData(this.params.page)
  },
  methods: {
    getReport() {
      this.loadingReport = true
      TransactionRepository.report({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          this.report = response.data.data
          this.loadingReport = false
        })
        .catch(() => {
          this.loadingReport = false
        })
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params })
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData()
    },
    search(searchText) {
      this.params.search = searchText
      this.updateQuerySting()
      this.getData()
    },
    reset() {
      this.params.search = ''
      this.params.sortBy = null
      this.params.type = null
      this.$refs.search.reset()
      this.updateQuerySting()
      this.getData()
    },
    spliceItem(id) {
      const deleteIndex = this.items.findIndex((item) => {
        return item.id === id
      })

      this.items.splice(deleteIndex, 1)
      this.total -= 1
    },
    deleteItem(id) {
      TransactionRepository.delete(id)
        .then((response) => {
          this.spliceItem(id)
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false
        })
    },
    getData() {
      if (this.params.sortPayment) {
        this.params.sortPay = this.params.sortPayment.id
      }
      if (this.params.sortType) {
        this.params.type = this.params.sortType.id
      }
      this.loading = true
      TransactionRepository.pre_order({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          console.log(response)
          let data = response.data.data.list
          this.items = [...data]
          this.total = response.data.data.total
          this.loading = false
          this.getReport()
        })
        .catch(() => {
          this.loading = false
        })
    },
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
  setup() {
    let keyFields = [...tableFields]
    const searchFields = [...searchInputs]
    return {
      keyFields,
      searchFields,
    }
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
